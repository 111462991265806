import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/theme-github";

export const Editor = ({text, onDownload}) => {
    return (
        <div>
            <button onClick={onDownload}>Download</button>
            <AceEditor 
                mode="text"
                theme="github"
                name="editor"
                readOnly
                editorProps={{ $blockScrolling: true }}
                value={text}
                style={{width:'100%'}}
            />
        </div>
    )
}