import './App.css';
import { FileUploader } from './uploader/Uploader';
import { Editor } from './editor/Editor';
import { useSelector } from 'react-redux';

function App() {

  const abaData = useSelector(state => state.abaData)

  const download = () => {
    const blob = new Blob([abaData])
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = 'generated.aba'
    link.click()
  }

  return (
    <div className='App'>     
      {abaData?<Editor text={abaData} onDownload={download}/>:<FileUploader/>}
    </div>
  );
}

export default App;
