import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: `uploader`,
    initialState: {
        step: 'init',
        abaData: '',
        error: '',
    },
    reducers: {
        error: (state, action) => {
            state.step = 'error'
            state.error = action.payload
            state.abaData = ''
        },
        onAbaData: (state, action) => {
            state.error = ''
            state.step = 'aba'
            state.abaData = action.payload
        }
    },
})

export const { error, onAbaData } = slice.actions;

export default slice.reducer;