import React, { useState } from 'react';
import ReactDOMServer  from 'react-dom/server';
import { useDispatch } from 'react-redux';
import DropzoneComponent from 'react-dropzone-component';
import {
    error, 
    onAbaData,
} from './uploaderSlice';
import './Uploader.scss';

const uploaderConfig = {
    iconFiletypes: ['.csv'],
    showFiletypeIcon: true,
    postUrl: '/api/transform'
};
var djsConfig = { 
    autoProcessQueue: false,
    
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
        <div className="dz-preview dz-file-preview">
          <div className="dz-details">
            <div className="dz-filename"><span data-dz-name="true"></span></div>
            <img data-dz-thumbnail="true" />
          </div>
          <div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress="true"></span></div>
          <div className="dz-success-mark"><span>✔</span></div>
          <div className="dz-error-mark"><span>✘</span></div>
          <div className="dz-error-message"><span data-dz-errormessage="true"></span></div>
        </div>
      )
}


//https://codesandbox.io/s/github/reduxjs/redux-essentials-counter-example/tree/master/?from-embed=&file=/src/features/counter/Counter.js


export const FileUploader = () => {

    const dispatch = useDispatch()

    const eventHandlers = {
        addedfile: (file, event) => {
            console.log(`I catch added file yaaaa ${JSON.stringify(file)}`)
        },
        success: (file, event) => {
            console.log(`I catch success file yaaaa ${JSON.stringify(file)}`)
            console.log(`I catch success event(not stringified) ${event}`)
            dispatch(onAbaData(event))
        },
        error: (file, event) => {
            console.log(`I catch error file yaaaa ${JSON.stringify(file)}`)
            dispatch(error('I am aba error'))
        },
        sending: (file, event) => {
            console.log(`I catch added file yaaaa ${JSON.stringify(file)}`)
        }
    }

    return (
    
        <div>
            <div className='text'>
                <h1>ABARGEN</h1>
                <p>
                Welcome to Evergen CSV to ABA file secure converter. To easily convert a CSV to an ABA file you will need to follow the below steps:
                </p>
                <p>
                1. Click here to download this template which has the correct column structure needed to complete the conversion.
                </p>
                <a href='/template.csv' download filename='Monthly Customer Receipt-CSV.csv'>CSV to ABA File Template</a>
                <img src='/csv_template.png'></img>
                <p>
                2. Upload your CSV by dropping it into the box below. 
                </p>
                <p>
                3. Download the ABA and upload it to the bank! That's it you have nailed it!
                </p>
            </div>
            <img className='gif-hero' src="https://media.giphy.com/media/9GLiEBLM0qiSQ/giphy.gif"></img>
            <DropzoneComponent
                config={uploaderConfig}
                eventHandlers={eventHandlers}
                djsConfig={ {
                    acceptedFiles: '.csv',
                    addRemoveLinks: true,
                }}
            />
            <div className='text'>
                <p>
                If you have any questions please write to Jack (jack.rudenko@evergen.com.au)
                </p>
            </div>

        </div>
    )
}